import React, { useState, useMemo, useRef, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { useCategoryQuery } from "../../../../../../hooks/useCategoryQuery";
import { useEmployerCreateJob } from "../../../../../../hooks/useEmployerCreateJob";
import { useEmployerUserDetails } from "../../../../../../hooks/queries/useEmployerUserDetails";
import { LoadSpinner } from "../../../../../../components/common/LoadSpinner";
import './index.scss';

export const CreateJob = ({ isCreateJobOpen, setIsCreateJobOpen }) => {
  const [data, setData] = useState({
    title: '',
    description: '',
    categoryId: '',
    amount: null,
    location: '',
    date: null,
    time: null
  });
  const [createdJobId, setCreatedJobId] = useState();
  const closeModal = () => setIsCreateJobOpen(false);
  const { data: categoryData } = useCategoryQuery();
  const { data: userData } = useEmployerUserDetails();
  const { mutate: createJob, isLoading } = useEmployerCreateJob();
  const history = useHistory();
  const categoryInput = useRef();

  const tax = parseInt(data?.amount) * parseFloat(process.env.REACT_APP_TAX_PERCENTAGE) / 100;
  const commission = parseInt(data?.amount) * parseInt(process.env.REACT_APP_COMMISSION_PERCENTAGE) / 100;
  const escrowFee = useMemo(() => {
    if (!data.amount) return 0.00;

    const fee = parseFloat(data?.amount) * parseFloat(process.env.REACT_APP_ESCROW) / 100;

    return fee > 2000 ? 2000 : fee;
  }, [data.amount]);

  const flutterwaveConfig = {
    public_key: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
    tx_ref: `${Date.now().toString()}:${createdJobId}:${userData?.data?.id}:employer:escrowFunded`,
    amount: parseInt(data?.amount) + tax + commission,
    currency: 'NGN',
    payment_options: 'card',
    customer: {
      email: userData?.data?.email,
      phone_number: userData?.data?.phoneNumber,
      name: `${userData?.data?.firstName} ${userData?.data?.lastName}`,
    },
    meta: {
      jobId: createdJobId
    },
    customizations: {
      title: 'Showkonnect',
      description: 'Escrow payment for job.',
      logo: 'https://res.cloudinary.com/dcq9wirxq/image/upload/v1698545334/efinder/dsebdomtlg963ijhlpmx.png',
    },
  };

  const handleFlutterPayment = useFlutterwave(flutterwaveConfig);

  useEffect(() => {

    if (!createdJobId || !handleFlutterPayment) return;

    handleFlutterPayment({
      callback: (response) => {
        console.log(response);
        closePaymentModal(); // this will close the modal programmatically
        history.push('/employers/jobs?status=active');
      },
      onClose: () => {
        closePaymentModal();
        history.push('/employers/jobs?status=paymentPending');
      },
    });
  }, [createdJobId, handleFlutterPayment]);

  const categoryOptionData = useMemo(() => {
    if (!categoryData || !categoryData.hasOwnProperty('data')) return [];
    else return categoryData.data;
  }, [categoryData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((state) => ({ ...state, [name]: value }));
  }

  const handleOnSubmit = (event) => {
    event.preventDefault();

    if (categoryInput.current.value === '' || categoryInput.current.value === '-- select an option --') {
      return categoryInput.current.setCustomValidity(
        `Select a Entertainment Sector`
      )
    }

    createJob(data, {
      onSuccess: (data) => {
        enqueueSnackbar(data.message, { variant: "success" });
        setCreatedJobId(data.id);
      },
      onError: (data) => {
        enqueueSnackbar(data.message, { variant: "error" });
      }
    })
  }

  return (
    <Modal show={isCreateJobOpen} onHide={closeModal} size='lg'>
      <Modal.Body>
        <button className="btn close" onClick={closeModal}>
          <span aria-hidden="true">×</span>
        </button>

        <div className='create-job__container container'>
          {
            isLoading && <LoadSpinner />
          }

          <h2 className="text-center text-dark create-job__header">Post A Job</h2>
          <p className="text-center create-job__sub-header">Enter details to create a job post</p>

          <form
            className="row g-3 needs-validation"
            onSubmit={handleOnSubmit}
          >
            <div className="col-md-12">
              <label htmlFor="categoryId" className="form-label">
                Category
              </label>
              <select
                className="form-select"
                id="categoryId"
                name="categoryId"
                required
                onChange={(e) => {
                  const { name, value } = e.target;

                  const category = categoryOptionData.find((data) => value == data.name);
                  setData((state) => ({ ...state, [name]: category.id }));
                  categoryInput.current.setCustomValidity('');
                }}
                ref={categoryInput}
              >
                <option disabled selected> -- select an option -- </option>
                {categoryOptionData.map((state, key) => (
                  <option key={key} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md-12">
              <label htmlFor="title" className="form-label">
                Who are you looking for?
              </label>
              <input
                type="text"
                className="form-control"
                id="title"
                name="title"
                required
                onChange={handleInputChange}
                value={data.title}
              />
            </div>

            <div className="col-12">
              <label htmlFor="description" className="form-label">
                Job Description
              </label>

              <textarea
                className="form-control"
                rows="4"
                id="description"
                name="description"
                required
                onChange={handleInputChange}
                value={data.description}
              ></textarea>
            </div>


            <div className="col-lg-6">
              <label htmlFor="lga" className="form-label">
                Location
              </label>
              <input
                required
                type='text'
                className={'form-control'}
                id='location'
                name='location'
                onChange={handleInputChange}
                value={data.location}
                autoComplete={'off'}
              />
            </div>

            <div className="col-lg-6">
              <label htmlFor="date" className="form-label">
                Date
              </label>
              <input
                required
                type="date"
                className="form-control text-uppercase"
                id="date"
                name="date"
                onChange={handleInputChange}
                value={data.date}
              />
            </div>

            <div className="col-lg-6">
              <label htmlFor="time" className="form-label">
                Time
              </label>
              <input
                type="time"
                className="form-control"
                id="time"
                name="time"
                onChange={handleInputChange}
                value={data.time}
                required
              />
            </div>

            <div className="col-lg-6">
              <label htmlFor="budgetTo" className="form-label">
                Amount (NGN)
              </label>
              <input
                type="number"
                className="form-control"
                id="amount"
                name="amount"
                onChange={handleInputChange}
                value={data.amount}
                required
              />
              {/*<FormError*/}
              {/*  touched={formik.touched.budgetTo}*/}
              {/*  message={formik.errors.budgetTo}*/}
              {/*/>*/}
            </div>

            <div className="col-12">
              <label htmlFor="budgetTo" className="create-job__total-amount">
                Escrow Fee ({parseFloat(process.env.REACT_APP_ESCROW)}%): <>&#8358;</><span>{escrowFee}</span>
              </label>

              <label htmlFor="budgetTo" className="create-job__total-amount">
                Contractual Fee ({parseFloat(process.env.REACT_APP_COMMISSION_PERCENTAGE)}%): <>&#8358;</><span>{commission || 0.00}</span>
              </label>

              <label htmlFor="budgetTo" className="create-job__total-amount">
                VAT ({parseFloat(process.env.REACT_APP_TAX_PERCENTAGE)}%): <>&#8358;</><span>{tax || 0.00}</span>
              </label>

              <label htmlFor="budgetTo" className="create-job__total-amount">
                Sub-Total: <>&#8358;</><span>{parseFloat(data?.amount) + tax + commission || escrowFee}</span>
              </label>
            </div>

            <div className="col-lg-12 col-lg-12">
              {/*<CustomGradientButton*/}
              {/*  text="Post Job"*/}
              {/*  fontColor="#fff"*/}
              {/*  width="100%"*/}
              {/*  borderRadius="5px"*/}
              {/*  backgroundImage="linear-gradient(#E5AD5A, #D26E2A)"*/}
              {/*  border="0"*/}
              {/*  btnLoading={false}*/}
              {/*  fontSize="15px"*/}
              {/*/>*/}

              <input className='submit-button' type='submit' value='Post Job' />
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
