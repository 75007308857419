import React from "react";
import { Link } from "react-router-dom";
import Typography from "../common/Typography";

const Footer = () => {
  let year = new Date().getFullYear();
  let text = `© ShowKonnect ${year} - All Rights Reserved.`;

  return (
    <div className="footer">
      <div className="container py-3">
        <div
          className="row"
          style={{
            paddingBottom: "3%",
            borderBottom: "0.5px solid #F3F6F9"
          }}
        >
          <div className="col-lg-3 col-sm-6 my-3">
            <h5 className="footer__title">Company</h5>

            <ul className="list-unstyled">
              <li className="my-3">
                <Link to="/about-us">About Us</Link>
              </li>
              {/*<li className="my-3">*/}
              {/*  <Link to="/career">Career</Link>*/}
              {/*</li>*/}
              <li className="my-3">
                <Link to="/contact-us">Contact Us</Link>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-sm-6 my-3">
            <h5 className="footer__title">Product</h5>

            <ul className="list-unstyled">
              <li className="my-3">
                <Link to="/signup">Create Account</Link>
              </li>
              <li className="my-3">
                <Link to="/login">Log in</Link>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-sm-12 my-3">
            <h5 className="footer__title">Legal</h5>

            <ul className="list-unstyled">
              <li className="my-3">
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li className="my-3">
                <Link to="/terms-and-condition">Terms & Conditions</Link>
              </li>
            </ul>
          </div>

          <div
            className="col-lg-3 col-sm-12 my-3"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start"
            }}
          >
            <h5 className="footer__title">Contact Us</h5>
            <ul className="list-unstyled">
              {/*<li className="my-3">*/}
              {/*  4th Floor, Mangrove House, 911 Light Street, Ikeja, Lagos.*/}
              {/*</li>*/}
              <li className="my-3">
                <a href="mailto:info@showkonnect.com" target="_blank">info@showkonnect.com</a>
              </li>
              <li className="my-3">
                <a href="tel:+2349887524444">+234 [0] 988 752 4444</a>
              </li>
            </ul>

            <Typography
              text="Connect with us"
              color="#F3F6F9"
              fontSize="14px"
              fontWeight="bold"
              fontStyle="normal"
              lineHeight="15.4px"
              textAlign="left"
              margin="3% 0"
            />

            <div className="social-spacing mb-1" style={{ color: "white", display: 'inline-flex', gap: '15px' }}>
              {/*<a href="https://facebook.com" target="blank">*/}
              {/*  <i className="fab fa-facebook-square fa-2x"></i>*/}
              {/*</a>*/}
              <a href="https://twitter.com/showkonnect" target="blank">
                <i className="fab fa-twitter fa-2x"></i>
              </a>
              {/*<a href="https://facebook.com" target="blank">*/}
              {/*  <i className="fab fa-linkedin-in fa-2x"></i>*/}
              {/*</a>*/}
              <a href="https://www.instagram.com/showkonnect/" target="blank">
                <i className="fab fa-instagram fa-2x"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="row">
          <Typography
            text={text}
            color="#F3F6F9"
            fontSize="16px"
            fontWeight="normal"
            fontStyle="normal"
            lineHeight="35px"
            textAlign="center"
            margin="2% 0"
            style={{ opacity: "0.6" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
