import React from "react";
import { Navbar } from "../../components/v2/navbar";
import Footer from "../../components/layout/Footer";
import './index.css';

const TermsAndCondition  = () => {
  return (
    <>
        <Navbar />

        <div className="container">
          <h3>Terms & Conditions</h3>
          <p>
            These terms and conditions ("Agreement") sets forth the general terms and conditions of your use of the "Efindar" application ("Application" or "Service") and any of its related products and services (collectively, "Services"). This Agreement is legally binding between you ("User", "you" or "your") and this Application developer ("Efindar", "we", "us" or "our"). By accessing and using the Application and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. If you are entering into this Agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this Agreement, in which case the terms "User", "you" or "your" shall refer to such entity. You acknowledge that this Agreement is a contract between you and Efindar, even though it is electronic and is not physically signed by you, and it governs your use of the Application and Services.
          </p>

          <ol className="main-list">
            <li>
              <h4>DEFINITIONS</h4>
              <p>
                In these Terms and Conditions of Use, the following capitalised terms shall have the following meanings, except where the context otherwise requires:
              </p>

              <p>
                “<b>Arbitration</b>” means a private form of dispute resolution submitted to an arbitrator, award of which is binding on parties
              </p>

              <p>
                "<b>Account</b>" means an account created by a User on the Application as part of Registration.
              </p>

              <p>
                “<b>Booking</b>” means reserving or securing the time of a performer to perform any services allowed by Efindar by a client
              </p>

              <p>
                “<b>Client</b>” means a user who seeks to engage a performer for any Efindar provided services
              </p>

              <p>
                “<b>Dispute</b>” means a dispute between a Client and Performer
              </p>

              <p>
                "<b>Intellectual Property Rights</b>" means all present and future rights conferred by statute, common law or equity in or in relation to any copyright and related rights, trademarks, designs, patents, inventions, goodwill and the right to sue for passing off, rights to inventions, rights to use, and all other related rights,
              </p>

              <p>
                “<b>Performance</b>” means the event for which the Client secures the time of the Performer
              </p>

              <p>
                “<b>Performer</b>” includes all Efindar artists, musicians, instrumentalists, actors/actresses, producers, directors including group of persons and other entertainers rendering service for a fee
              </p>

              <p>
                "<b>Register</b>" means to create an Account on the Efindar Application and
              </p>

              <p>
                "<b>Registration</b>" means the act of creating such an Account.
              </p>

              <p>
                "<b>Services</b>" means all the services provided by Efindar Limited via the Efindar Application to Users, and "Service" means any one of them,
              </p>

              <p>
                "<b>Users</b>" means users of the Efindar Application, including you and "User" means any one of them.
              </p>
            </li>

            <li>
              <h4>ACCOUNTS AND MEMBERSHIP</h4>

              <p>
                Upon creation of an account in the Efindar Application, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. We may, but have no obligation to, monitor and review new accounts before you may sign in and start using the Services. Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may re-register for our Services upon application and grant of same to Efindar Limited. We may block your email address and Internet protocol address to prevent further registration.
              </p>
              <ol type="i">
                <li>
                  <h5>Registration</h5>

                  <p>
                    To access and use most features of the Application, you must register for an Account. Subject to the Site Terms of Use, certain portions are available to Site Visitors, including those portions before your Account registration is accepted. Upon account creation, it will be the prerequisite of Efindar to accept registration. Efindar reserves the right to decline a registration to join Efindar or to add an Account of any type (i.e., as a Client or Performer), for any lawful reason, including supply and demand, cost to maintain data, or other business considerations.
                  </p>

                  <p>
                    You agree to open not more than one account either as a Client or Performer on Efindar site. Violation of this will give Efindar the discretion to delete the new account and already existing account in addition to whatever other penalty applicable for any confusion created by the situation such as reporting to the lawful authorities on the suspicion of commission of fraud with multiple accounts.
                  </p>

                  <p>
                    If you create an Account as an employee or agent on behalf of a company, you represent and warrant that you are authorized to enter into binding contracts, including the Terms of Service, on behalf of yourself and the company.
                  </p>
                </li>

                <li>
                  <h5>Account Eligibility</h5>

                  <p>
                    To register for an Account or use Efindar Services, you must, and hereby represent that you: (a) are an individual of 18 years and above who has the capacity to enter into legal relations according to the law of your region (b) a legal entity duly registered under the law and carrying on business legally (c) employee or agent of and authorized to act for and bind an independent business (whether it be as a self-employed individual/sole proprietor or as a corporation, limited liability company, or other entity); (d) will use the Efindar Site and Site Services for business purposes only; (e) will comply with any licensing, registration, or other requirements with respect to your business, or the business for which you are acting, and the provision of Performer Services.
                  </p>
                </li>

                <li>
                  <h5>Profile Creation</h5>

                  <p>
                    To register for an Account to join Efindar, you must complete a User profile (“Profile”), which you consent to be shown to other Users and, unless you change your privacy settings, to be shown to the public. You agree to provide true, accurate, and complete information on your Profile and all registration and other forms you access on the Site or provide to us and to update your information to maintain its truthfulness, accuracy, and completeness. You agree not to provide any false or misleading information about your identity or location, your business, your skills, or the services your business provides and to correct any such information that is or becomes false or misleading.
                  </p>
                </li>

                <li>
                  <h5>Account Permissions</h5>

                  <p>
                    You agree not to request or allow another person to create an Account on your behalf, for your use, or for your benefit, except that an authorized employee or agent may create an Account on behalf of your business. By granting other Users permissions under your Account, including as a Team Member or Agency Member, you represent and warrant that: (a) the User is authorized to act on your behalf; and (b) you are fully responsible and liable for the User’s acts and omissions, including for obligations and liabilities relating to making payments and entering into Service Contracts and the Terms of Service. If any such User violates the Terms of Service, it may affect your ability to use the Efindar application and Site Services. Upon closure of an Account, Efindar may close any or all related Accounts.
                  </p>
                </li>

                <li>
                  <h5>Verification of Account</h5>

                  <p>
                    Registering for an Account with Efindar gives Efindar the permission to from time to time, subject your Account to verification, including, but not limited to, validation against third-party databases or the verification of one or more official government or legal documents that confirm your identity, your location, and your ability to act on behalf of your business on Efindar. You authorize Efindar, directly or through third parties, to make any inquiries necessary to validate your identity, your location, and confirm your ownership of your email address or financial accounts, subject to applicable law. When requested, you must timely provide us with complete information about yourself and your business, which includes, but is not limited to, providing official government or legal documents. During verification some Account features may be temporarily limited, Account features will be restored upon successful verification.
                  </p>
                </li>

                <li>
                  <h5>Usernames and Passwords</h5>

                  <p>
                    You are entirely responsible for safeguarding and maintaining the confidentiality of your username and password. You agree not to share your username or password with any person, and, if you are a legal entity who is not a natural person, to only share your username and password with a person who is authorized to use your Account. You authorize Efindar to assume that any person using the Site with your username and password, either is you or is authorized to act for you. You agree to notify us immediately if you suspect or become aware of any unauthorized use of your Account or any unauthorized access to the password for any Account. You further agree not to use the Account or log in with the username and password of another User of the Site if (a) you are not authorized to use both or (b) the use would violate the Terms of Service.
                  </p>
                </li>
              </ol>

              <li>
                <h4>USER CONTENT</h4>

                <p>
                  We do not own any data, information or material (collectively, "Content") that you submit in the Efindar Application in the course of using the Service. You shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all submitted Content. We may, but have no obligation to monitor and review the Content in the Efindar Application submitted or created using our Services by you. You grant us permission to access, copy, distribute, store, transmit, reformat, display and perform the Content of your user account solely as required for the purpose of providing the Services to you. Without limiting any of those representations or warranties, we have the right, though not the obligation, to, in our own sole discretion, refuse or remove any Content that, in our reasonable opinion, violates any of our policies or is in any way harmful or objectionable. You also grant us the license to use, reproduce, adapt, modify, publish or distribute the Content created by you or stored in your user account for commercial, marketing or any similar purpose.
                </p>

                <ol type="i">
                  <li>
                    <h5>License to Use Material</h5>

                    <p>
                      When you upload any text, images, photographs, audio content, video (“Material”) via the Application, you represent that you are the owner of the Material, or have proper authorization from the owner of the Material to use, reproduce and distribute it. You hereby grant us a worldwide, royalty-free, non-exclusive license to use the Material to promote any products or services on Efindar application and Site.
                    </p>
                  </li>

                  <li>
                    <h5>Content on the Application and Service</h5>

                    <p>
                      It is your responsibility to ensure that any products, or information available through the Efindar Application or the Services meet your specific requirements before making any payment. Efindar will not be responsible for any loss of payment based on a representation made by a Performer or Client which you relied upon to enter into an agreement.
                    </p>
                  </li>
                </ol>
              </li>
            </li>

            <li>
              <h4>LINKS TO OTHER RESOURCES</h4>

              <p>
                Although the Efindar Application and Services may link to other resources (such as websites, mobile applications, etc.) for advertisement, promotion, provision of information or product enhancement purposes, we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked resource, unless specifically stated herein. Some of the links in the Efindar Application may be "affiliate links". This means if you click on the link and purchase an item, the Operator will receive an affiliate commission. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their resources. We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties. You should carefully review the legal statements and other conditions of use of any resource which you access through a link in the Efindar Application and Services. You agree that your linking to any other off-site resources is entirely at your own risk.
              </p>
            </li>

            <li>
              <h4>ESCROW PAYMENT</h4>

              <p>
                Payment to Performers by Clients will be processed through escrow. The Client will make payment into the escrow account to be held for the Performer and released after the deduction of necessary charges and taxes upon completion of the contract between the Client and Performer. Where payment is made by any Client directly into the account of the Performer even if such is Performer is engaged through the Efindar platform, such Client does so at his/her own risk and Efindar will not be responsible for any loss in that transaction neither will Efindar make any refund of such payment where the performer does not render service for the payment made
              </p>

              <p>
                If a Client and a Performer enter into a Contract, the contract shall be governed by Escrow Instructions. To the extent permitted by applicable law, we may modify these Escrow Instructions without prior notice to you, and any revisions to these Escrow Instructions will take effect when posted on the Site unless otherwise stated.
              </p>

              <p>
                The Client represents that he/she has the ability and right to make the payment for the services of the Performer. The Performer also acknowledges that upon payment being made by the Client, a contract is entered into and repudiation at this point will attract fee.
              </p>

              <ol type="i">
                <li>
                  <h5>Release of funds</h5>

                  <p>
                    Client and Performer hereby authorizes the escrow company to pay the contract price to the Escrow Account of the Performer or Client as soon  the work is certified to be completed satisfactorily.
                  </p>

                  <p>
                    Upon completion of the work, the Performer requests the release of the fund, where the Client is satisfied, the client also authorizes release funds. However, where the client is not satisfied, he indicates on his portal not to release funds and the matter is set for dispute resolution.
                  </p>

                  <p>
                    Upon the agreement to release funds, Client agrees that the instruction to the escrow company to release funds is irrevocable. Such instruction is Client’s authorization to transfer funds to Performer from the Client Escrow Account or authorization to charge Client’s Payment Method. Such instruction is also Client’s representation that Client has received, inspected and accepted the subject work or expense. Therefore, and in consideration of services described in this Agreement, Client agrees that once Efindar has charged Client’s Payment Method, the charge is non-refundable.
                  </p>
                </li>

                <li>
                  <h5>CHARGES</h5>

                  <p>
                    For every payment made into the escrow account by the Client, a processing fee of 5% will be automatically deducted.
                  </p>

                  <p>
                    Efindar will charge a Performer a 10% commission on every contract accepted which is deductible from the contract fee held in escrow on behalf of the performer. Upon completion of the contract, the remaining 90% will be paid to the Performer.
                  </p>

                  <p>
                    These charges are non-refundable.
                  </p>
                </li>

                <li>
                  <h5>Refunds and Cancellations</h5>

                  <p>
                    Where either Client or Performer intends to cancel, it is expected that one informs the other party of the intention to cancel. If there are no funds in escrow, Client or Performer can cancel the contract at any time. If funds are held in escrow, refunds and cancellations will be made with payment of charges by the party cancelling the contract.
                  </p>

                  <p>
                    Where a Performer cancels the contract, the Client can reshare the job and hire another Performer with the funds already being held in Escrow. It will be at the option of the Client to either get a refund less the 5% charge or hire another Performer to do the job.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <h4>TAXES</h4>

              <p>
                Where applicable, Efindar may collect withholding tax, VAT and any other applicable tax on behalf of Federal Inland Revenue Service (FIRS) or appropriate tax authority. The Escrow Company may also collect Taxes on membership fees and the cost for Contracts.
              </p>
            </li>

            <li>
              <h4>DISPUTE RESOLUTION</h4>

              <p>
                The formation, interpretation, and performance of this Agreement and any disputes arising out of it shall be governed by the substantive and procedural laws of Nigeria or the relevant jurisdiction where the parties reside or carry out business, to the extent applicable, where there is a dispute as to which law to apply, the law of Nigeria shall prevail. Where dispute arises, the Client and Performer are enjoined to resolve the dispute amicably by dialogue. However, where dispute involves payment of fees or refunds which cannot be settled by the parties, Efindar shall be notified of the existence of the dispute which shall then be referred to the legal team of Efindar for resolution. The cost of resolution of dispute shall be borne equally by both parties. Where either party is not satisfied with the dispute resolution by the legal team of Efindar, recourse shall be made to Arbitration by the joint appointment of a sole Arbitrator. The Award of the Arbitration shall be binding on both parties. At this point, Efindar will no longer be involved except to provide documentation or any material in its custody as required and requested by the Arbitrator. Efindar shall respect the award of the Arbitrator and release the funds according to the findings of the Arbitrator
              </p>
            </li>

            <li>
              <h4>PROHIBITED USES</h4>

              <p>
                In addition to other terms as set forth in the Agreement, you are prohibited from using the Efindar Application and Services or Content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, state or local government regulations, rules or laws (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of the  others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Efindar Application and Services, third party products and services, or the Internet; (h) to spam, phish, pharm, pretext, spider, crawl, or scrape; (i) for any obscene or immoral purpose; or (j) to interfere with or circumvent the security features of the Efindar Application and Services, third party products and services, or the Internet. We reserve the right to terminate your use of the Efindar Application and Services for violating any of the prohibited uses.
              </p>

              <p>
                In addition to the aforementioned, you undertake not to use or permit anyone else to use the Services or Mobile Application:-
              </p>

              <ol type="i">
                <li>
                  to send or receive any material which is threatening, grossly offensive, of an indecent, obscene or menacing character, blasphemous or defamatory of any person, in contempt of court or in breach of confidence, copyright, rights of personality, publicity or privacy or any other third party rights;
                </li>

                <li>
                  to send or receive any material for which you have not obtained all necessary licences and/or approvals (from us or third parties); or which constitutes or encourages conduct that would be considered a criminal offence, give rise to civil liability, or otherwise be contrary to the law of or infringe the rights of any third party in any country in the world;
                </li>

                <li>
                  to send or receive any material which is technically harmful (including computer viruses, logic bombs, Trojan horses, worms, harmful components, corrupted data or other malicious software or harmful data);
                </li>

                <li>
                  to cause annoyance, inconvenience or needless anxiety, to incite public hatred against any person, association or lawful group;
                </li>

                <li>
                  for any fraudulent purpose;
                </li>

                <li>
                  in such a way as to, or commit any act that would or does, impose an unreasonable or disproportionately large load on our infrastructure.
                </li>

                <li>
                  for a purpose other than which we have designed them or intended them to be used;
                </li>
              </ol>
            </li>

            <li>
              <h4>INTELLECTUAL PROPERTY RIGHTS</h4>

              <p>
                This Agreement does not transfer to you any intellectual property owned by Efindar or third parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely with Efindar and the relevant third parties. All trademarks, service marks, graphics and logos used in connection with the Efindar Application and Services, are trademarks or registered trademarks of Efindar or its licensors. Other trademarks, service marks, graphics and logos used in connection with the Efindar Application and Services may be the trademarks of other third parties. Your use of the Efindar Application and Services grants you no right or license to reproduce or otherwise use any of the Efindar’s or third party’s trademarks.
              </p>
            </li>

            <li>
              <h4>LIMITATION OF LIABILITY</h4>

              <p>
                To the fullest extent permitted by applicable law, in no event will Efindar, its affiliates, directors, officers, employees, agents, suppliers or licensors be liable to any person for any indirect, incidental, special, punitive, cover or consequential damages (including, without limitation, damages for lost profits, revenue, sales, goodwill, use of content, impact on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if the liable party has been advised as to the possibility of such damages or could have foreseen such damages.
              </p>

              <p>
                We shall not be liable for any acts or omissions of any third parties howsoever caused, and for any direct, indirect, incidental, special, consequential or punitive damages, howsoever caused, resulting from or in connection with the mobile application and the services offered in the Efindar application, your access to, use of or inability to use the Efindar application or the services offered in the application, reliance on or downloading from the Efindar application and/or services, or any delays, inaccuracies in the information or in its transmission including but not limited to damages for loss of business or profits, use, data or other intangible, even if we have been advised of the possibility of such damages.
              </p>

              <p>
                We shall not be liable in contract, tort (including negligence or breach of statutory duty) or otherwise howsoever and whatever the cause thereof, for any indirect, consequential, collateral, special or incidental loss or damage suffered or incurred by you in connection with the Efindar Application and these Terms and Conditions of Use. For the purposes of these Terms and Conditions of Use, indirect or consequential loss or damage includes, without limitation, loss of revenue, profits, anticipated savings or business, loss of data or goodwill, loss of use or value of any equipment including software, claims of third parties, and all associated and incidental costs and expenses.
              </p>

              <p>
                The above exclusions and limitations apply only to the extent permitted by law. None of your statutory rights as a consumer that cannot be excluded or limited are affected.
              </p>
            </li>

            <li>
              <h4>INDEMNIFICATION</h4>

              <p>
                You agree to indemnify and hold Efindar and its affiliates, directors, officers, employees, agents, suppliers and licensors harmless from and against any liabilities, losses, damages or costs, including reasonable attorneys' fees, incurred in connection with or arising from any third party allegations, claims, actions, disputes, or demands asserted against any of them as a result of or relating to your Content, your use of the Efindar Application and Services or any willful misconduct on your part.
              </p>
            </li>

            <li>
              <h4>SEVERABILITY</h4>

              <p>
                All rights and restrictions contained in this Agreement may be exercised and shall be applicable and binding only to the extent that they do not violate any applicable laws and are intended to be limited to the extent necessary so that they will not render this Agreement illegal, invalid or unenforceable. If any provision or portion of any provision of this Agreement shall be held to be illegal, invalid or unenforceable by a court of competent jurisdiction, it is the intention of the parties that the remaining provisions or portions thereof shall constitute their agreement with respect to the subject matter hereof, and all such remaining provisions or portions thereof shall remain in full force and effect.
              </p>
            </li>

            <li>
              <h4>CHANGES AND AMENDMENTS</h4>

              <p>
                We reserve the right to modify this Agreement or its terms relating to the Efindar Application and Services at any time, effective upon posting of an updated version of this Agreement in the Efindar Application. When we do, we will post a notification in the Efindar Application and Site. Continued use of the Efindar Application and Services after any such changes shall constitute your consent to such changes.
              </p>
            </li>

            <li>
              <h4>ACCEPTANCE OF TERMS</h4>

              <p>
                You acknowledge that you have read this Agreement and agree to all its terms and conditions. By accessing and using the Efindar Application and Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to access or use the Efindar Application and Services.
              </p>
            </li>

            <li>
              <h4>NOTICES</h4>

              <p>
                All notices to a User required by Efindar or Escrow Instructions will be made via email sent by Efindar to the User’s registered email address. Users are solely responsible for maintaining a current, active email address registered with Efindar, for checking their email and for responding to notices sent by Efindar to the User’s registered email address.
              </p>
            </li>

          </ol>
        </div>

        <Footer />
    </>
  )
}

export default TermsAndCondition;
