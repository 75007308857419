import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { PrivateRouteWrapper, LoggedInWrapper } from "./gate";

import About from "./About";
import ContactUs from "./ContactUs";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndCondition from "./TermsAndCondition";
import Notfound from "./Notfound";

// start of new
// entertainers
import { Landing } from './Landing';
import { Layout } from "./v2/talents/common-blocks/layout";
import { Login as TalentLogin } from './v2/talents/authentication/login';
import { ForgotPassword as NewTalentForgotPassword } from './v2/talents/authentication/forgot-password';
import { ResetPassword as NewResetPassword } from './v2/talents/authentication/reset-password';
import { Signup as TalentSignup } from './v2/talents/authentication/signup';

import { Dashboard as TalentsDashboard } from './v2/talents/dashboard';
import { MyJobs as TalentsMyJobs } from './v2/talents/my-jobs';
import { Profile as TalentsProfile } from './v2/talents/profile';

// employers
import { Login as EmployersLogin } from './v2/employers/authentication/login';
import { Signup as EmployersSignup } from './v2/employers/authentication/signup';

import { Dashboard as EmployersDashbaord } from './v2/employers/dashboard';
import { Transactions as EmployersTransactions } from './v2/employers/transactions';
import { Jobs as EmployersJobs } from './v2/employers/jobs';
import { ViewJob as EmployersViewJobs } from "./v2/employers/viewJob";
import { Profile as EmployersProfile } from './v2/employers/profile';
import { JobSeeker as EmployerJobSeeker } from "./v2/employers/jobSeeker";

import { NewLogin } from "./v2/auth/login";
import { NewSignup } from "./v2/auth/signup";
// end of new


const AppIndex = () => {
  return (
    <Router>

      <Switch>

        <Route path="/contact-us">
          <ContactUs />
        </Route>

        <Route path="/about-us">
          <About />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/terms-and-condition">
          <TermsAndCondition />
        </Route>

        {/*new start*/}
        <Route path="/login">
          <LoggedInWrapper>
            <TalentLogin />
          </LoggedInWrapper>
        </Route>
        <Route path="/signup">
          <LoggedInWrapper>
            <TalentSignup />
          </LoggedInWrapper>
        </Route>
        <Route path="/forgot-password">
          <NewTalentForgotPassword />
        </Route>
        <Route path='/reset-password'>
          <NewResetPassword />
        </Route>

        {/*Employers*/}
          <Route path="/employers/login">
            <LoggedInWrapper>
              <EmployersLogin />
            </LoggedInWrapper>
          </Route>
          <Route path="/employers/signup">
            <LoggedInWrapper>
              <EmployersSignup />
            </LoggedInWrapper>
          </Route>

          <Route path={"/employers/dashboard"}>
            <PrivateRouteWrapper userType={'employer'}>
              <EmployersDashbaord />
            </PrivateRouteWrapper>
          </Route>

          <Route path={"/employers/transactions"}>
            <PrivateRouteWrapper userType={'employer'}>
              <EmployersTransactions />
            </PrivateRouteWrapper>
          </Route>

        <Route path="/employers/jobs/:id/job-seeker/:jobSeekerId">
          <PrivateRouteWrapper  userType={'employer'}>
            <EmployerJobSeeker />
          </PrivateRouteWrapper>
        </Route>

          <Route path="/employers/jobs/:id">
            <PrivateRouteWrapper  userType={'employer'}>
              <EmployersViewJobs />
            </PrivateRouteWrapper>
          </Route>

          <Route path="/employers/jobs">
            <PrivateRouteWrapper  userType={'employer'}>
              <EmployersJobs />
            </PrivateRouteWrapper>
          </Route>

          <Route path="/employers/profile">
            <PrivateRouteWrapper  userType={'employer'}>
              <EmployersProfile />
            </PrivateRouteWrapper>
          </Route>
        {/*Employers end*/}

        {/*Talents Start*/}
          {/*<PrivateRoute>*/}
            <Route path="/dashboard">
              <PrivateRouteWrapper  userType={'jobSeeker'}>
                <Layout>
                  <TalentsDashboard />
                </Layout>
              </PrivateRouteWrapper>
            </Route>

            <Route path="/jobs">
              <PrivateRouteWrapper  userType={'jobSeeker'}>
                <Layout>
                  <TalentsMyJobs />
                </Layout>
              </PrivateRouteWrapper>
            </Route>

            <Route path="/profile">
              <PrivateRouteWrapper  userType={'jobSeeker'}>
                <Layout>
                  <TalentsProfile />
                </Layout>
              </PrivateRouteWrapper>
            </Route>
          {/*</PrivateRoute>*/}

        <Route path="/new-signup">
          <LoggedInWrapper>
            <NewSignup />
          </LoggedInWrapper>
        </Route>

        <Route path="/new-login">
          <LoggedInWrapper>
            <NewLogin />
          </LoggedInWrapper>
        </Route>

        <Route path="/">
          <LoggedInWrapper>
            <Landing />
          </LoggedInWrapper>
        </Route>

        {/*end new*/}

        <Route path="*">
          <Notfound />
        </Route>
      </Switch>
    </Router>
  );
};

export default AppIndex;
