import React, { useState, useMemo } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Avatar, Pagination, Table, Divider } from "antd";
import { enqueueSnackbar } from "notistack";
import { useJobSeekerUserDetails } from "../../../../hooks/queries/useJobSeekerUserDetails";
import { useJobSeekerGetTransactions } from "../../../../hooks/queries/useJobSeekersGetTransactions";
import { useJobSeekerGetMedia } from "../../../../hooks/queries/useJobSeekerGetMedia";
import { useJobSeekerUpdateProfile } from "../../../../hooks/mutations/useJobSeekerUpdateProfile";
import Media from "../../../../components/Media";
import { CropSaveImage } from "../../../../components/common/CropSaveImage";
import { LoadSpinner } from "../../../../components/common/LoadSpinner";
import { EditProfile } from './blocks/edit-profile';
import './index.scss';

export const Profile = () => {
  const [tabKey, setTabKey] = useState( 'media' );
  const [shouldShowEditModal, setShouldShowEditModal] = useState(false);
  const [cropImageUrl, setCropImageUrl] = useState('');
  const [cropProfileImage, setCropProfileImage] = useState(false);
  const [file, setFile] = useState(null);

  const { data: transactionsData } = useJobSeekerGetTransactions();
  const { data: userDetails, refetch: refetchUserDetails, isLoading: isLoadingJobSeeker } = useJobSeekerUserDetails();
  const { data: jobSeekerMedia, refetch: refetchJobSeekerMedia, isLoading: isLoadingJobSeekerMedia } = useJobSeekerGetMedia();
  const { mutate: updateJobSeekerProfile, isLoading: isUpdatingProfile} = useJobSeekerUpdateProfile();

  const transactionsColumns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    }
  ];

  const transactionsDataSource = useMemo(() => {
    if (!transactionsData) return [];
    const result = [];

    transactionsData?.data?.map((data, index) => result.push({
      id: index + 1,
      name: <span><b>Payment for:</b> {data.job.title}</span>,
      price: data.amount,
      date: data.createdAt.substring(0, 10)
    }));
    return result;
  }, [transactionsData]);
  const isLoading = useMemo(() => {
    return !!isUpdatingProfile || !!isLoadingJobSeeker || !!isLoadingJobSeekerMedia;
  }, [isUpdatingProfile, isLoadingJobSeeker, isLoadingJobSeekerMedia]);

  const closeShouldshowEditModal = () => {
    setShouldShowEditModal(false);
    refetchUserDetails();
  }

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    setCropImageUrl(URL.createObjectURL(file));
    setCropProfileImage(true)
  }

  return (
    <div className='talents-profile__main'>
      <EditProfile
        shouldShowEditModal={shouldShowEditModal}
        closeModal={closeShouldshowEditModal}
        userDetails={userDetails?.data}
      />
      {
        isLoading && <LoadSpinner />
      }
      <div className='container-fluid container talents-profile__container'>
        <div className='row talents-profile__profile'>
          <div className='talents-profile__profile-layout'>
            <div className='talents-profile__profile-image'>
              <input type='file' accept='image/*' multiple={false} style={{ display: 'none' }} />

              <label htmlFor="profilePhoto" className='employers-profile__profile-label'>
                <input
                  accept="image/*"
                  id="profilePhoto"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleProfilePictureChange}
                />
                <Avatar
                  src={userDetails?.data?.profilePicture}
                  style={{ cursor: 'pointer' }}
                  size={150}
                />
              </label>

              <CropSaveImage
                showModal={cropProfileImage}
                closeModal={() => {
                  setCropProfileImage(false);
                  setCropImageUrl('');
                  setFile(null);
                }}
                photoURL={cropImageUrl}
                setFormPhotoURL={(url) => {
                  setCropProfileImage(false);
                  updateJobSeekerProfile({
                    firstName: userDetails?.data?.firstName,
                    lastName: userDetails?.data?.lastName,
                    phoneNumber: userDetails?.data?.phoneNumber,
                    profilePicture: url
                  }, {
                    onSuccess: (response) => {
                      refetchUserDetails();
                      enqueueSnackbar(response.message, { variant: 'success' });
                    },
                    onError: (error) => {
                      refetchUserDetails();
                      enqueueSnackbar('Error: Please try again later', { variant: 'error' });
                    }
                  });
                }}
                setPhotoURL={(url) => setCropImageUrl(url)}
                setFile={setFile}
              />
            </div>

            <div className='talents-profile__profile-name'>
              <div>
                <p className='talents-profile__profile-name__text talents-profile__profile-name__text-name'>{userDetails?.data?.firstName} {userDetails?.data?.lastName}</p>
                <p className='talents-profile__profile-name__text talents-profile__profile-name__text-skill'>{userDetails?.data?.category?.name}</p>
              </div>
              {/*<div className='talents-profile__profile-name__location'>*/}
              {/*  <FaMapMarkerAlt /> <span>Lagos, Nigeria</span>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className='talents-profile__profile-edit' onClick={() => setShouldShowEditModal(true)}>
            <span>Edit Profile</span>
          </div>
        </div>

        <div className='talents-profile__profile__bio'>
          <p className='talents-profile__profile__bio-title'>Biography</p>
          <Divider className='talents-profile__profile__bio-divider' />
          <p className='talents-profile__profile__bio-p'>{userDetails?.data?.bio ? userDetails.data.bio : 'Update Biography...'}</p>
        </div>

        <Tabs className='talents-profile__tabs' activeKey={tabKey} onSelect={(key) => setTabKey(key)}>
          <Tab eventKey='media' title='Media'>
            <Media media={jobSeekerMedia?.data?.rows} isPublic={false} fetchMedia={refetchJobSeekerMedia} />
          </Tab>

          <Tab eventKey='transactions' title='Transactions'>
            <div className='talents-profile__account'>
              <p><span>Account Balance:</span> <span className='talents-profile__account-balance'>{userDetails?.data?.account?.balance || 0}</span></p>
              <button className='talents-profile__account-btn'>Pay Out</button>

            </div>

            <Table
              columns={transactionsColumns}
              dataSource={transactionsDataSource}
              pagination={
                {
                  hideOnSinglePage: true
                }
              }
            />

            <Pagination defaultCurrent={1} total={30} className='talent-job__table__pagination' />
          </Tab>
        </Tabs>
      </div>
    </div>
  )
};
